import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionGrid from "../components/section/SectionGrid"
import SectionInfo from "../components/section/SectionInfo"
import ContactData from "../components/contact/ContactData"
import SectionImage from "../components/section/SectionImage"

import ueber_uns from "../assets/images/presentational/ueber-uns.webp"

const Contact = () => {
  return (
    <>
      <Seo
        title={"Kontakt | Avalum"}
        description={"Wir freuen uns auf Ihre Kontaktaufnahme"}
      />
      <Section>
        <SectionGrid>
          <SectionImage src={ueber_uns} />
          <SectionInfo>
            <ContactData />
          </SectionInfo>
        </SectionGrid>
      </Section>
    </>
  )
}

export default Contact
